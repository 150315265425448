/* App.css */

/* General Styles */
.app {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f0fff4; /* Light green background */
  min-height: 100vh; /* Make sure the app covers the full viewport height */
  box-sizing: border-box;
}

h1 {
  color: #064e3b; /* Dark green color for contrast */
  text-align: center;
  margin-bottom: 40px;
}

/* Dashboard Styles */
.dashboard {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

@media (max-width: 768px) {
  .dashboard {
    grid-template-columns: 1fr;
  }
}

/* Card Styles */
.dashboard > div {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
}

.dashboard h2 {
  color: #047857; /* A darker shade of green for headings */
  margin-bottom: 15px;
}

.dashboard p {
  color: #065f46; /* Dark green for text for better readability */
  font-size: 1.2em;
}

/* TotalAmount Component Styles */
.total-amount {
  position: relative;
}

.amount-text {
  font-size: 3em;
  font-weight: bold;
  color: #064e3b;
  margin-bottom: 20px;
}

.progress-bar {
  height: 40px;
  background-color: #e5e7eb;
  border-radius: 20px;
  margin-top: 20px;
  position: relative;
  display: flex;
  align-items: center;
}

.progress {
  height: 100%;
  background-color: #10b981;
  border-radius: 20px;
  transition: width 2s ease-in-out;
}

.goal-amount {
  position: absolute;
  right: 20px;
  color: #064e3b;
  font-weight: bold;
}

/* AmountByCategory Component Styles */
.amount-by-category {
  text-align: center;
}

.amount-by-category table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.amount-by-category th,
.amount-by-category td {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #e5e7eb;
}

.amount-by-category th {
  background-color: #047857;
  color: #ffffff;
}

.amount-by-category .category-column {
  text-align: left;
}

.amount-by-category tbody tr:last-child td {
  border-bottom: none;
}

.amount-by-category tbody tr:hover {
  background-color: #f0fff4;
}



/* DonationsByGender Component Styles */
.donations-by-gender {
  text-align: center;
}

.donations-by-gender .recharts-responsive-container {
  margin: 0 auto;
}

.donations-by-gender .recharts-legend-item-text {
  color: #064e3b;
}



/* DonationsByAge Component Styles */
.donations-by-age {
  text-align: center;
}

.bubble-chart {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 20px;
}

.bubble-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.bubble {
  border-radius: 50%;
  transition: transform 0.3s;
}

.bubble:hover {
  transform: scale(1.1);
}

.label {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
  font-weight: bold;
}

.label2 {
  font-size: 14px;
  color: #666;
}

.amount {
  margin-top: 5px;
  font-size: 16px;
  color: #333;
  font-weight: bold;
}


.pledge-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 20px;
}

.pledge-button {
  padding: 10px 20px;
  background-color: #10b981;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.pledge-button:hover {
  background-color: #047857;
}

/* pledge- page form */

.pledge-page {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f0fff4;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #064e3b;
}

input[type="number"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.submit-button {
  padding: 10px 20px;
  background-color: #10b981;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #047857;
}

.back-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #e5e7eb;
  color: #064e3b;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
}

.back-button:hover {
  background-color: #d1d5db;
}

/* DonationsByLocation Component Styles */
.donations-by-location {
  text-align: center;
}

.donations-by-location .recharts-responsive-container {
  margin: 0 auto;
}

.donations-by-location .recharts-legend-item-text {
  color: #064e3b;
}

.pledge-button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  padding: 0 20px;
}

.pledge-button {
  padding: 10px 20px;
  background-color: #10b981;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
}

.pledge-button:hover {
  background-color: #047857;
}

.general-message {
  margin-top: 20px;
  margin-bottom: 10px;
  color: blue;
  font-weight: bold;
}

.error-message {
  margin-top: 10px;
  color: red;
  font-weight: bold;
}